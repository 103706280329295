// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation WebAuthnLogin(
    $data: WebAuthnAssertionInput!,
    $email: String!,
    $key: String!
  ) {
    webauthnLogin(
      data: $data,
      email: $email,
      key: $key
    ) {
      token
      refreshToken: refresh_token
      expiresIn: expires_in
    }
  }`;

export default MUTATION;
